.HeaderBanner{
position: relative;
background: #000 0% 0% no-repeat padding-box;
border: 1px solid #707070;
opacity: 1;
width: 100%;
}
.bannerImg1{
    margin-top: 3%;
    display: flex;
    justify-content: start;
}
.bannerImg2{
    display: flex;
    justify-content: end;
}
.ThoughtFoundrylogo1{
    width:60%;
   }
   .bulbs1{
    width:40%;
   }
.submitIdeaBtn{
    // margin: 20px 0 !important;
}
.mb3{
    margin-bottom: 3rem;
}
