@import '../../variables.scss';

.loginContainer{
//    position: relative;
//   background-image: url("../../assets/loginBG.jpg");
//   background-position: center center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   height:100vh;
  padding:3% 0;
  @media (max-width: 820px) {
    padding:12% 0;
}
//    padding: 10%;
   
}

.formLabel{
    color: #fff !important;
}
.formcontrol{
    border:0;
    border-radius: 0;
    border-bottom: 1px solid #fff;
    background-color: #000 !important;
    color: #fff !important;
}
.customBtn{
    background-image: linear-gradient(to right, #fbda0b, #ff7c00);
    border: 0;
    border-radius: 50px;
    color:#000 !important;
    width:100%;
    margin: 5% 10%;
}

.center{
 display: flex;
 justify-content: center;
}
.forgotPwd{
    color:lightgray;
}
.mb2{
margin-bottom: 2rem;
}
.register{
    color:#000;
    text-decoration: none;
}
.pl4{
 padding-left: 1.5rem;
}
.light{
    background-color: #000;
    padding-top: 16px;
    width: 24rem;
    margin: auto;
    border-radius: 10px;
}

.alert-danger {
        margin-top: 5px;
        padding: 3px 10px;
}
.customTab{
    background-color: #fff;
    width: 10.13rem;
    margin: auto;
    border: 0;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 50px;
    
}

.customTab:hover{
    border: 0;
}
