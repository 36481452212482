.role-card {
    min-height: 250px;
    max-height: 250px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    padding: 20px;
    position: relative;
    margin-bottom: 50px;
    h6 {
        font: normal normal medium 18px/22px Roboto;
        color: #3d3d3d;
    }
    button {
        background: #fec230 0% 0% no-repeat padding-box;
        border-radius: 5px;
        padding: 5px 15px;
    }
    .skill-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        max-height: 80px;
        overflow: auto;
        scroll-behavior: smooth;
        span {
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #cccccc;
            border-radius: 10px;
            padding: 5px;
            color: #8a8a8e;
            font-size: 14px;
            margin-right: 1px;
        }
    }
    .skill-list::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #f5f5f5;
        overflow-x: auto;
    }
    .skill-list::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
        overflow-x: auto;
    }
    .skill-list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #8a8a8e;
        overflow-x: auto;
    }
    .remove-role {
        position: absolute;
        top: -15px;
        right: -15px;
        cursor: pointer;
    }
    .remove-nominee {
        position: absolute;
        top: -5px;
        right: -7px;
        cursor: pointer;
    }
    .profile-circle {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 5px solid #fff;
        background-color: #46b7e9;
        color: #fff;
        text-align: center;
        font: normal normal bold 40px Roboto !important;
        padding: 6px;
        cursor: pointer;
        position: absolute;
        top: -33px;
    }
}
