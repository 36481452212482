.loadMore {
    margin: 60px 0;
}

.historyDetail{
  [data-prefix="fas"] {
    color: #4CAF50;
    width: 30px;
    height: 30px;
  }
  .dateHistory{
    padding: 2px;
    margin-left: 10px;
    font: normal normal normal 12px/16px Roboto;
    color: #FFFFFF;
    width: auto;
    height: 20px;
    background: #007BFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    text-align: center;
  }
  .dateIcon{
    display: flex;
    align-items: center;
  }
  .historyDescription{
    border-left-style: solid;
    border-color: #D5D5D5;
    padding:5px 20px 10px;
    .customCards{
      border-radius: 5px;
    }
    .card-body {
      flex: 1 1 auto;
      padding: 1rem 0rem;
    }
  }
  .historyBorder{
    padding-left: 15px;
  }
}

.loadMoreButton{
  text-align: left;
  font: normal normal 900 18px Lato;
  letter-spacing: 0px;
  color: #0C5BF2;
  opacity: 1;
  border: none;
  background: none;
}