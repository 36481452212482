//boostrap 5
@import "~bootstrap/scss/bootstrap";

// custom variables
@import "./variables.scss";

//google fonts
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap");


html,
body,
p,
div {
    font-family: 'Roboto';
}

body {
    background: #fff;
    overflow-x: hidden;
    width: 100%;
}

section {
    padding: 25px 0;
}

h2 {
    font-size: 22px;
    color: #5a5a5a;
}

.slick-next:before,
.slick-prev:before,
small {
    color: #5a5a5a;
}

small {
    font-size: 12px;
}

.avatar {
    color: #868e96;
    font-weight: 600;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    font-size: 0.875rem;
    user-select: none;
}
input:invalid ~ .invalid-feedback, textarea:invalid ~ .invalid-feedback, select:invalid ~ .invalid-feedback, .invalid-feedback.show{
    display: block;
}
.img-responsive{
    max-width: 100%;
    height: auto;
}
.big-title{
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 4rem;
}
.btn{
    font-weight: bold;
    // padding: .8rem 1.5rem;
}
.mr-16{
    margin: 16px;
}
.padding0{
    padding:0 !important;
}

.nav-tabs .nav-link.active{
    background-image: linear-gradient(to right, #fbda0b, #ff7c00);
    border: 0 !important;
    border-radius: 50px !important;
}

.nav-link{
    color: #000 !important;
}

.nav-tabs .nav-link{
    border: 0 !important;
    border-radius: 50px;
}

@media (max-width: 1024px) {
.submit-idea{
   .col {
    flex: none;
   }
  }
}

.header-bell {
    height: 20px;
    width: 20px;
    background-color: $blue;
    border-radius: 250%;
    border: 4px solid $blue;
    align-items: center;
    text-align: center;
    color: #fff;
    display: none;
    @media (max-width: 820px) {
        display: block;
    }
  }

.live-typing{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color:#000;
    margin-bottom:10px;
    padding:2rem;
    @media (max-width: 820px) {
        padding:16px;
    }
}

.desktop{
    @media (max-width: 820px) {
        display: none;
    }
}
.mobile{
    @media (min-width: 821px) {
        display: none;
    }
}
