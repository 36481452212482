.ideaDetail {
    background: #f9f9f9 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 100%;
    padding-left: 26px;
    padding-right: 50px;
    padding-top: 26px;
    padding-bottom: 26px;
}
.name {
    font: normal normal 900 30px/36px "Roboto";
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0px;
}
.ans {
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-size: 18px;
    margin-top: 20px;
}
.profile1-circle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 5px solid #fff;
    background-color: #46b7e9;
    color: #fff;
    text-align: center;
    font: normal normal bold 40px Roboto !important;
    padding: 6px;
    cursor: pointer;
}
.editIcon {
    height: 25px;
    width: 25px;
    cursor: pointer;
}
.edit-heading {
    font: normal normal bold 26px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.formBg {
    background: #f9fdff 0% 0% no-repeat padding-box;
    opacity: 1;
}
.form-btns {
    width: 150px;
    height: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    font: normal normal bold 18px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.tabContainer {
    // background: #E9E5E5 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #00000029;
    opacity: 1;
    height: 50px;
    margin: 0 2px 5px;
}
.tabText {
    text-align: center;
    font: normal normal 900 30px/36px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding: 5px;
    cursor: pointer;
}
.goback {
    text-align: left;
    font: normal normal normal 22px Roboto;
    letter-spacing: 0px;
    color: #2680eb;
    opacity: 1;
    text-decoration: none !important;
}
.radioBtn {
    margin-right: 30px;
}
.radioBtnInput {
    margin: 10px;
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(1.5);
    accent-color: #fec230 !important;
}
.commentTextArea {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c7c7c7;
    opacity: 1;
    width: 100%;
    margin-top: 20px;
}
.modal-profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #fff;
    margin: 16px 4px;
    align-self: center;
    background-color: #46b7e9;
    color: #fff;
    text-align: center;
    font: normal normal bold 30px Roboto !important;
    padding: 6px;
    cursor: pointer;
}
.modal_name {
    padding: 12px 10px;
    text-align: left;
    font-family: Roboto;
    font-size: 23px;
    color: #082848;
}
.followBtn {
    width: 150px;
    height: 30px;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    font-size: 19px !important;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    padding: 2px;
    cursor: pointer;
    margin-top: 10px;
}
#follow {
    background: #007bff 0% 0% no-repeat padding-box;
}
#following {
    background: #fec230 0% 0% no-repeat padding-box;
}
.modal-dialog {
    max-width: 700px !important;
    max-height: 600px !important;
}
.modal-heading {
    border-bottom: 1px solid #007bff;
}

.add-role {
    text-decoration: underline;
    font: normal normal bold 18px/24px Roboto;
    color: #0c5bf2;
    cursor: pointer;
}
.errorMsg {
    color: red;
    font-size: 14px;
}
.likeNominateModal{
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: auto;
}

.pointsSuccess {
    text-align: left;
    font: normal normal bold 18px Roboto;
    letter-spacing: 0px;
    color: #3d3d3d;
    opacity: 1;
    margin-bottom: 0px;
}

.text-justify {
    text-align: justify;
}