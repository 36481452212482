.title-idea {
    text-align: left;
    font: normal normal bold 26px/34px Roboto;
    color: #000000;
}

.goback {
    text-align: left;
    font: normal normal normal 22px Roboto;
    letter-spacing: 0px;
    color: #2680eb;
    opacity: 1;
    text-decoration: none !important;
}
