@import "../../../variables.scss";

.bell {
  height: 20px;
  width: 20px;
  background-color: $blue;
  border-radius: 250%;
  border: 4px solid $blue;
  -webkit-transform: rotate(90deg);
  align-items: center;
  text-align: center;
}
.bellDot {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dots {
  position: absolute;
  top: 22px;
  right: 3px;
  width: 12px;
  height: 12px;

  background-color: #f21313ed;
  border-radius: 50%;
}

.NotifyStyle {
  display: flex;
  margin: 0px;
  text-align: center;
  background-color: $blue_1 !important;
  color: $white !important;
  height: 60px;
  width: 180px;
  font-size: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
 
}
.alignRignt {
  right: 0;
  position: fixed;
  transform: translateX(60px) rotate(-90deg);
  @media (min-width: 1201px) {
    top: 50%;
  }
  @media (max-width: 1200px) and (min-width: 993px) {
    top: 50%;
  }
  @media (max-width: 992px) and (min-width: 769px) {
    top: 50%;
  }
  @media (max-width: 768px) and (min-width: 577px) {
    top: 60%;
    display:none;
  }
  @media (max-width: 576px) and (min-width: 0px) {
    top: 50%;
    display:none;
  }
}

.blue {
  background: $blue_1;
}
.welcomeMsg {
  background: #101010 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  // padding: 16px 9%;
  position: fixed;
  top: 68px;
  width: 100%;
}
.notiText{
  display: 'none' !important;
}

