.approveComplexityRadio,.approveStatusRadio{
    display: inline-flex;
    .radioLabel {
      display: block;
      position: relative;
      padding: 0px 5px 5px 30px;
      width: 125px;
      cursor: pointer;
      font-size: 18px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    
    /* Hide the browser's default radio button */
    .radioLabel input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    
    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
      border-radius: 50%;
    }
    
    /* On mouse-over, add a grey background color */
    .radioLabel:hover input ~ .checkmark {
      background-color: #ccc;
    }
    
    /* When the radio button is checked, add a blue background */
    .radioLabel input:checked ~ .checkmark {
      background-color: #FEC230;
    }
    
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the indicator (dot/circle) when checked */
    .radioLabel input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the indicator (dot/circle) */
    .radioLabel .checkmark:after {
         top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    }
}

.approveIdeaDetail {
  textarea{
    overflow: auto;
    resize: none;
    background: none;
    box-shadow: 3px 3px #cecece;
  }
  label{
    font: normal normal medium 18px Roboto !important;
  }
}

.approveIdeaActions{
  display: flex;
  align-items: center;
  justify-content: center;
  Button{
    margin:10px;
    font-weight:bold
  }
}
