.attachment-section {
    .supported-file {
        margin: 0;
    }
    input {
        display: none;
    }
    img {
        cursor: pointer;
    }
    .upload-img {
        display: flex;
        // flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
    }
    .img-preview {
        width: 100px;
        display: inline-flex;
        flex-direction: column;
        p {
            width: 100px;
            word-break: break-all;
            font-size: 12px;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .preview-img {
            width: 80px;
            height: 80px;
            object-fit: contain;
        }
    }
    .img-preview-icons {
        display: flex;
        align-items: flex-start;
    }
    .delete-img {
        width: 18px;
        margin-left: -10px;
    }
    .upload-icon {
        margin: 20px 0;
    }
    .disable {
        opacity: 0.5;
        pointer-events: none;
    }
    .attachment-error{
        color: #DC3545;
    }
}

