.main-border {
  display: flex;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000017;
  opacity: 1;
  margin-top: 10px;
}

.avatar {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  margin-left: 15px;
}

.main {
  margin-top: 97px;
  color: black;
}

.leftBorder {
  border-left: 2px solid #29b6f6;
}

h1 {
  color: black;
  font-weight: bold;
  margin-bottom: 25px;
}
.cardGridImage {
  width: 100px;
  margin: auto;
  position: absolute;
  // top: -67px;
  border-radius: 50%;
  overflow: hidden;
  left: 0;
  right: 0;
  background: #fff;
  padding: 5px;
  box-shadow: 0px 0px 7px 1px #ccc;
  img {
    border-radius: 50%;
    margin: 0 !important;
    position: relative;
  }
}

.bell_style {
  color: black;
}

.welcomeMsg {
  background: #9f9f9f 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: left;
  font: normal normal bold 20px/12px Roboto;
  letter-spacing: 1.98px;
  color: #2b2b2b;
  opacity: 1;
  height: 40px;
  // padding: 16px 9%;
  position: relative;
  top: 40px;
}
.goback {
  text-align: left;
  font: normal normal normal 22px Roboto;
  letter-spacing: 0px;
  color: #2680eb;
  opacity: 1;
  text-decoration: none !important;
}
.notificationHeading {
  @media (min-width: 1201px) {
    text-align: left;
    font: normal normal bold 46px Roboto;
    letter-spacing: 0px;
    color: #3d3d3d;
    opacity: 1;
    margin: 20px 0;
  }
  @media (max-width: 1200px) and (min-width: 993px) {
    text-align: left;
    font: normal normal bold 40px Roboto;
    letter-spacing: 0px;
    color: #3d3d3d;
    opacity: 1;
    margin: 20px 0;
  }
  @media (max-width: 992px) and (min-width: 769px) {
    text-align: left;
    font: normal normal bold 34px Roboto;
    letter-spacing: 0px;
    color: #3d3d3d;
    opacity: 1;
    margin: 20px 0;
  }
  @media (max-width: 768px) and (min-width: 577px) {
    text-align: left;
    font: normal normal bold 28px Roboto;
    letter-spacing: 0px;
    color: #3d3d3d;
    opacity: 1;
    margin: 20px 0;
  }
  @media (max-width: 576px) and (min-width: 0px) {
    text-align: left;
    font: normal normal bold 24px Roboto;
    letter-spacing: 0px;
    color: #3d3d3d;
    opacity: 1;
    margin: 20px 0;
  }
}
.notificationDiv1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000017;
  opacity: 1;
  border-left: 4px solid #29B6F6;
  margin-bottom: 16px;
  cursor: pointer;
}
.notificationDiv2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000017;
  opacity: 1;
  margin-bottom: 16px;
  cursor: pointer;

}
.notificationprofile-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid #fff;
  margin: 16px 4px;
  align-self: center;
  background-color: #FEC230;
  color:#000;
  text-align: center;
  font: normal normal bold 40px Roboto !important;
  padding: 5px;
}
.notificationUsername {
  text-align: left;
  font: normal normal medium 14px/19px Roboto !important;
  letter-spacing: 0px;
  color: #333333;
}
.line1 {
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 3px;
}
.line2 {
  text-align: left;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  color: #777777;
  opacity: 1;
  margin-bottom: 3px;
}
.line3 {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #777777;
  opacity: 1;
  margin-bottom: 3px;
}
.contentDivNotification{
  padding: 20px;
}
.noNotification{
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  font-style: italic;
    color: gray;
  @media (max-width: 820px) {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
}
