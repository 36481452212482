@import "../../../variables.scss";

.footer {
    background: $white;
    color: #9aa0ac;
    border-top: 1px solid rgba(0, 40, 100, 0.12);
    font-size: 0.875rem;
    background-color: #ebebeb;
}

.footerLogo {
    padding: 24px;
    align-items: center;
    display: block;
    margin: auto;
    height: 100px;
}

.footerText1 {
    background: #9f9f9f 0% 0% no-repeat padding-box;
    opacity: 1;
    font: normal normal 600 15px/20px Roboto;
    letter-spacing: 0px;
    color: #3d3d3d;
    opacity: 1;
    text-align: left;
    padding: 8px;

    @media (max-width: 820px) {
        font: normal normal 600 12px/20px Roboto;
    }
}
.footerText2 {
    background: #9f9f9f 0% 0% no-repeat padding-box;
    opacity: 1;
    font: normal normal 600 15px/20px Roboto;
    letter-spacing: 0px;
    color: #3d3d3d;
    opacity: 1;
    text-align: right;
    padding: 8px;
    @media (max-width: 820px) {
        font: normal normal 600 12px/20px Roboto;
    }
}

.footerDiv{
    // display: flex;
    // justify-content: space-between !important;
    background: #9f9f9f 0% 0% no-repeat padding-box;
    // padding: 0 7%;
}

.footerEmail{
    text-align: left;
    font: normal normal 600 15px/20px Noto Sans;
    letter-spacing: 0px;
    color: #1968FF;

    @media (max-width: 820px) {
        font: normal normal 600 12px/20px Noto Sans;
    }
}

.displayMobile {
    @media (max-width: 820px) {
display: block;
    }
    @media (min-width: 821px) {
        display: none;
            }
}
