.HeaderBanner{
    position: relative;
    background: #000 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    width: 100%;
    }
    .bannerImg1{
        margin-top: 3%;
        display: flex;
        justify-content: start;
    }
    .bannerImg2{
        display: flex;
        justify-content: end;
    }
    .ThoughtFoundrylogo2{
        width: 60%;
        height: 200px;
        margin-top: 2rem;
        @media (max-width: 820px) {
           height:auto;
           margin-top: 0;
           width: 80%;
        }
       }
       .ai{
        width:98%;
        margin-right: -23%;
       }
    .submitIdeaBtn{
        // margin: 20px 0 !important;
    }
    .mb3{
        margin-bottom: 3rem;
    }
    