$third-color: #262626;
$white: #fff;
$yellow: #E9AA10;
$blue:#00618D;
$blue_1:#46B7E9;
$white:#fff;
$grey:#808080;
$black:#0000;

//font family
$font-regular: "Roboto";
