.header {
  height: 55px;
  #basic-navbar-nav {
    button {
      margin-right: 1rem;
    }
    
  }
  .nav-link {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 1rem;
    font: normal normal 700 20px Roboto;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    @media (max-width: 820px) {
      margin-right: 0;
    }
  }
  [data-prefix="fas"] {
    margin-right: 5px;
  }
  .dropdown-toggle.nav-link {
    display: flex;
    align-items: center;
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 5px;
      background-color: #fff;
      color: #000;
      text-align: center;
      font-size: 20px;
      @media (max-width: 820px) {
        margin: 0;
      }
    }
  }
  .dropdown-menu[data-bs-popper] {
    right: 0;
    left: auto;
  }
  .logo {
    font-family: helvetica;
    display: block;
    position: relative;
    font-size: 24px;
    color: #fff;
  }
  .logo:before {
    content: "";
    position: absolute;
    top: 12px;
    left: 0;
    height: 3px;
    width: 60%;
    background-color: #fff;
  }
  .logo:after {
    content: "Where idea gets moulded";
    position: absolute;
    font-family: helvetica;
    left: 0;
    bottom: -2px;
    font-size: 9px;
    letter-spacing: 1px;
    width: 100%;
    background: #fff;
    color: #333;
    text-align: center;
    line-height: 1.25;
  }
  .activeNav {
    border-bottom: 3px solid #fec230;
  }
  .bg-black {
    background: #ebe6e6 0 0 no-repeat padding-box!important;
    opacity: 1;
    // padding-left: 7%;
    // padding-right: 7%;
  }
  .WelcomeMsg{
    font: normal normal 700 20px Roboto;
    letter-spacing: 0px;
    color: #000000;
    @media (max-width: 820px) {
      font: normal normal 700 16px Roboto;
    }
  }

  .defaultScreen{cursor: pointer};
  .nav-item.show .dropdown-toggle::after {
    // display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }
}
