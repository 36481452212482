.commentInputDiv {
    background: #f3f3f3 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #00000029;
    border: 1px solid #707070;
    border-radius: 20px;
    opacity: 1;
    margin: 10px 2px;
}
.commentInput {
    background: #f3f3f3 0% 0% no-repeat padding-box !important;
    border: 0;
    width: 95%;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    outline: none;
}
.profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    align-self: center;
    background-color: #fec230;
    color: #000;
    text-align: center;
    font: normal normal bold 30px Roboto !important;
    padding: 2px;
    cursor: pointer;
}
.commentDiv {
    background: #fbfbfb 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #00000029;
    opacity: 1;
    border-radius: 20px;
    padding: 10px;
}
.username {
    font: normal normal 900 14px "Roboto";
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    text-align: left !important;
}
.commentDes {
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    word-break: break-all;
    min-height: 20px;
    font-size: 16px;
    text-align: left !important;
    font-family: Roboto;
}
.username1 {
    font: normal normal 900 14px "Roboto";
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    text-align: right !important;
}
.des1 {
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0px;
    word-break: break-all;
    min-height: 20px;
    font-size: 16px;
    text-align: right !important;
    font-family: Roboto;
}
.commentRow {
    padding: 16px;
}
.commentSubDiv {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    margin-top: 10px;
    font-size: 16px;
    img {
        cursor: pointer;
    }
    // col {
    //     margin: auto;
    //     padding: 0 !important;
    // }
    #reply {
        margin-top: -10px;
    }
    .thumbsUp{
        margin-top: -13px;
        margin-right: 20px;
    }
}
.thumbsUp-icon {
    height: 16px;
    width: 16px;
}
.replyCommentInput {
    margin-left: 15%;
}
