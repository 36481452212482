.SidebarStyle {
    background: #f7f7f7 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #00000029;
    border: 1px solid #707070;
    opacity: 1;
    height: 100%;
}
.profile-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 10px solid #fff;
    background-color: #fec230;
    color: #000;
    text-align: center;
    font: normal normal bold 130px Roboto !important;
    padding: 20px;
    margin: auto;
    cursor: pointer;
    @media (max-width: 820px) {
      width: 100px;
      height: 100px;
      border: 5px solid #fff;
      font: normal normal bold 60px Roboto !important;
      padding: 12px;
    }
}
.eye-icon {
    height: 13px;
    width: 21px;
}
.seen-count {
    font: normal normal bold 12px/13px "Roboto";
    letter-spacing: 0px;
    color: #8a8a8e;
    opacity: 1;
}
.submitter-name {
    font: normal normal 900 18px/22px "Roboto";
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 30px;
    margin-bottom: 24px;
}
.date {
    font: normal normal medium 18px/22px "Roboto";
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 28px;
}
.status {
    font: normal normal medium 18px/22px "Roboto";
    letter-spacing: 0px;
    color: #0c2fe1;
    opacity: 1;
    margin-bottom: 28px;
}
.like-icon {
    width: 40px;
    height: 40px;
    margin-top: 20px;
    cursor: pointer;
}
.interest-icon {
    width: 60px;
    // height: 40px;
    margin-top: 20px;
    cursor: pointer;
}

.hand-icon {
    width: 54px;
    height: 54px;
    margin-top: 20px;
}
.count-text {
    font: normal normal bold 14px/3px "Roboto";
    letter-spacing: 0px;
    color: #8a8a8e;
    opacity: 1;
}
.mrt-5 {
    margin-top: 14px;
}
.doc1 {
    font: normal normal bold 18px/24px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 88px;
    margin-bottom: 4px;
}
.doc2 {
    font: normal normal medium 14px/17px Roboto;
    letter-spacing: 0px;
    color: #555555;
    opacity: 1;
    margin: 0;
}
.filename {
    font: normal normal bold 14px/9px "Roboto";
    letter-spacing: 0px;
    color: #8a8a8e;
    opacity: 1;
    padding: 5px;
}
.knowMore{
 cursor: pointer;
 color: #0c2fe1;
}
